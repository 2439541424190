import { Component, OnInit, PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { RouterExtService } from './routerExt.service';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/browser';
import { SeverityLevel } from '@sentry/types';

@Component({
  selector: 'app-not-found',
  templateUrl: 'not-found.component.html',
})
export class NotFoundComponent implements OnInit {
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(APP_ID) private appId: string,

    private routerExtService: RouterExtService,
    private router: Router
  ) {}

  ngOnInit() {
    /*  if (isPlatformServer(this.platformId) && this.response) {
      this.response.status(200);
    } */

    const routerLog: RouterLog = {
      current: this.router.url,
      previous: this.routerExtService.getPreviousUrl(),
    };

    const source = routerLog.current === '/notfound' ? routerLog.previous : routerLog.current;

    if (source.endsWith('.png') || source.endsWith('.json') || source.endsWith('shop-sessions')) {
      return;
    }

    const levelInfo: SeverityLevel = 'info';
    Sentry.captureMessage('User navigated to "NotFound" page from:' + source, levelInfo);
  }
}

export interface RouterLog {
  current: string;
  previous: string;
}
