import { captureException, SentryErrorHandler } from '@sentry/angular-ivy';
export class CustomSentryErrorHandler extends SentryErrorHandler {
  constructor() {
    super({ logErrors: false });
  }

  handleError(error: any): void {
    const exception = error.originalError || error.error || error;
    captureException(exception);
    super.handleError(error);
  }
}
